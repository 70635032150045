.App {
  text-align: center;
  height: 100vh;
  color: #333333
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  /* overwirte all max-width value smaller than 960px in all media queries */
  /* overwriting max-width 540 @ mw 576 & max-width 720 @ mw 768 */
  /* providing wider width (smaller x-margin) across tablet and dektop */
  max-width: 960px;
}

.card-deck .card {
  /* make sure the cards don't get too narrow */
  min-width: 220px;
}

.go-button {
  border-radius: 8px;
  background-color: #1943ef;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: "Georgia, serif";
  font-weight: 550;
  padding: 10px 15px;
  transition: all 0.5s;
  cursor: pointer;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.15);
}

.go-button{
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.go-button:after {
  content: '»';
  position: absolute;
  opacity: 0;  
  top: 8px;
  right: -10px;
  transition: 0.5s;
}

.go-button:hover{
  padding-right: 24px;
  padding-left:6px;
}

.go-button:hover:after {
  opacity: 1;
  right: 10px;
}
