.display {
}
.portal {
  margin: 0 auto;
  justify-content: 'center';
  display: flex;
  background-color: #fff;
}


.member-data{
  display: flex;
  justify-content: center;
  margin: 15px auto;

}

.member-data-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px auto 10px 0px;
  width: fit-content;
}

.left-dots-container {
  margin-top: 10%;
  height: 70px


}

.dots-1 {
  height: 63px;

  width: auto;
  position: fixed;
  top:20%;
  left: 0;
  z-index: 4;
}

.dots-2 {
  height: 63px;
  width: auto;
  z-index: 5;
  position: fixed;
  top: 20%;
  left: 77px;

}

.member-portal {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 476px;
  width: 100%;
  z-index: 8;
  margin: 10% auto;
  margin: 0 auto -15%;
}

.your-memberships {
  color: var(--blue-whale);
  font-family: var(--font-family-gilroy-bold-☞);
  font-size: var(--font-size-xl);
  font-weight: 400;
  letter-spacing: 0;
  margin: 0 auto;
  min-height: 37px;
  text-align: center;
  width: 100%;

}

.member-card {
  background-color: #fdfdfd;
  border-radius: 17.17px;
  box-shadow: 0px 3.43px 50px #9daaaab2;
  margin: 18px auto 0;
  width: fit-content;
}

.member-card-title {
  background-color: var(--new-car);
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 3.43px 21.47px #9daaaa26;
  width: 100%;
  padding: 15px 0;
}

.member-card-contents {
  margin: 15px auto;
  height: 100%;
}

.merchant-name {
  color: var(--white);
  font-family: var(--font-family-gilroy-semibold-☞);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 0;
  margin: 0px auto;
  text-align: center;
  width: 100%;
}

.credits-available {
  color: var(--black);
  font-family: var(--font-family-gilroy-semibold-☞);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0;
  margin: 12px auto;
  padding: 8px 20px;
  width: 220px;
  min-height: 19px;
  border: solid steelblue 2px;
  border-radius: 17px;
  white-space: pre-line;

}
.change-or-cancel-button {
  background-color: var(--new-car);  
  color: var(--white);
  font-family: var(--font-family-gilroy-bold-☞);
  font-family: var(--font-family-gilroy-semibold-☞);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0;
  margin: 0 auto;
  width: 220px;
  padding: 8px 20px;
  min-height: 19px;
  border-radius: 17px;
  border-width: 0px;
}

.group-1573 {
  align-items: flex-start;
  display: flex;
  height: 16px;
  left: 335px;
  min-width: 338px;
  position: fixed;
  top: 653px;
  z-index: 6;

}

.customer-support-co {
  color: transparent;
  font-family: var(--font-family-gilroy-semibold-☞);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  min-height: 16px;
  min-width: 336px;
  text-align: center;

}

.span0 {
  color: var(--new-car);
  font-weight: 400;
}

.span1 {
  color: var(--heather);
  font-weight: 400;
}

.span2 {
  color: #22415f;
  font-family: var(--font-family-gilroy-semibold-☞);
  font-weight: 400;

}

.group-1585 {
  min-height: 193px;
  min-width: 269px;
  z-index: 10;

}

.icon-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 2.52px;
  min-height: 144px;
  width: 18px;
}

.icon {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  min-height: 193px;
  width: 238px;
}


.flex-col-item {
  letter-spacing: 0;
  min-height: 19px;
  font-family: var(--font-family-gilroy-semibold-☞);
}

.logged-in-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.artboard-52x-5 {
  height: 30px;
  object-fit: cover;
  width: auto;
  margin: 7px 0;
}

.title-block {
}

.title-loggedin {
  color: var(--new-car);
  font-family: var(--font-family-gilroy-bold-☞);
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
}

.subtitle-loggedin{
  color: var(--new-car);
  font-family: var(--font-family-gilroy-bold-☞);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0;
}

.group-1583 {
  align-items: center;
  display: flex;
  min-width: 216px;
  z-index: 11;
  background-color: royalblue;
  border-radius: 15px;
  margin: 20px 10px 0 0;

}


.change-or-cancel-plan {
  background-color: var(--new-car);  color: var(--white);
  font-family: var(--font-family-gilroy-bold-☞);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: 0;
  min-width: 216px;
}


.right-dots-container {
  margin-top:60%;
  height: 70px

}


.dots-3 {
  height: 63px;
  width: auto;
  z-index: 3;
  position: fixed;
  top: 70%;
  right: 77px;
}

.dots-4 {
  height: 63px;
  width: auto;
  z-index: 3;
  position: fixed;
  top: 70%;
  right: 0px;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@font-face {
  font-family: "Gilroy-SemiBold-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Gilroy-SemiBold-Regular") format("opentype");
}
@font-face {
  font-family: "Gilroy-Regular-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Gilroy-Regular") format("opentype");
}
@font-face {
  font-family: "Gilroy-Bold-☞";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Gilroy-Bold-☞") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBold-☞";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Gilroy-SemiBold-☞") format("truetype");
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.cards-stack {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}