body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --amplify-primary-color: #333333;
  --amplify-primary-tint: #F7F7F7; 
  --amplify-primary-shade: #F7F7F7;

  --black: #000000;
  --blue-whale: #0b2e4e;
  --blue-whale-2: #0c2e4f4c;
  --blue-whale-3: #0c2e4f;
  --heather: #b6c0ca;
  --new-car: #1943ef;
  --royal-blue: #2e54f0;
  --viking: #64cce3;
  --white: #ffffff;
 
  --font-size-l: 22px;
  --font-size-m: 19.6px;
  --font-size-s: 15.5px;
  --font-size-xl: 30px;
  --font-size-xs: 12.9px;
  --font-size-xxl: 32.2px;
 
  --font-family-gilroy-bold-☞: "Gilroy-Bold-☞", Helvetica;
  --font-family-gilroy-regular-regular: "Gilroy-Regular-Regular", Helvetica;
  --font-family-gilroy-semibold-regular: "Gilroy-SemiBold-Regular", Helvetica;
  --font-family-gilroy-semibold-☞: "Gilroy-SemiBold-☞", Helvetica;
}
.gilroy-regular-regular-normal-black-15-5px {
  color: var(--black);
  font-family: var(--font-family-gilroy-regular-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.gilroy-semibold-regular-normal-black-15-5px {
  color: var(--black);
  font-family: var(--font-family-gilroy-semibold-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
