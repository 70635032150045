.gilroy-semibold--normal-black-14-5px {
    color: var(--black);
    font-family: var(--font-family-gilroy-semibold-☞);
    font-size: 14.5px;
    font-style: normal;
    font-weight: 400;
  }
  
  .gilroy-regular--normal-blue-whale-12-1px {
    color: var(--blue-whale);
    font-family: var(--font-family-gilroy-regular-☞);
    font-size: 12.1px;
    font-style: normal;
    font-weight: 400;
  }
  
  .border-0-8px-blue-whale-2 {
    border: 0.8px solid var(--blue-whale-2);
  }

.desktop-5 {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    border-top: solid yellow 2px;
}

.login-page {
    background: url("./img/rectangle-3@1x.png");
    background-image:url("./img/rectangle-3@1x.png") ;
    width: 100%;
    height: 100%;

}
.vector-layer {
    background-image: url("./img/vector@1x.png");
    height: 100%;
    width: 100%;
    background-size: cover;
    border: solid transparent 0.1px;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

.logo {
    height: 40px;
    object-fit: cover;
    width: auto;
}

.title-block {
    margin-left: 10px;
  }


.title {
    color: var(--white);
    font-family: var(--font-family-gilroy-bold-☞);
    font-size: var(--font-size-xxl);
    font-weight: 400;
    letter-spacing: 0;
}

.subtitle {
    color: var(--white);
    font-family: var(--font-family-gilroy-bold-☞);
    font-size: var(--font-size-s);
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 0px;
}

.login-box {
    width: 275px;
    border-radius: 20px;
    background-color: white;
    margin: 0 auto;
    padding: 20px 15px;
    margin-top: 10%;
    z-index: 100;
}

.sign-in {
    align-self: center;
    color: var(--blue-whale);
    font-family: var(--font-family-gilroy-bold-☞);
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    margin: 0 auto;
}

.field-title {
    letter-spacing: 0;
    font-family: var(--font-family-gilroy-semibold-☞);
    min-height: 11px;
    width: 100%;
    text-align:left;
    color: var(--black);
    font-size: 14.5px;
    margin-left: 10px;
    margin-top: 15px;
}

.field-hint {
    color: #707070;
    font-family: var(--font-family-gilroy-semibold-☞);
    font-size: 12.1px;
    font-weight: 400;
    letter-spacing: 0;
    min-height: 11px;
    width: 100%;
    text-align: left;
    border-radius: 16.12px;
    height: 35px;
    min-width: 254px;
    padding: 11.3px 11.3px;
    margin-top: 7px;
}

.forgot-your-password-button {
    font-family: var(--font-family-gilroy-semibold-☞);
    color: var(--royal-blue);
    font-size: 12.5px;
    letter-spacing: 0;
    margin-top: 16px;
    min-height: 15px;
    min-width: 122px;
    text-align: right;
    background-color: white;
    border: solid white 0px;
}

.sign-in-button {
    font-family: var(--font-family-gilroy-semibold-☞);
    color: white;
    background-color: var(--royal-blue);
    border-radius: 15px;
    width: 100%;
    border: solid white 0px;
    padding: 5px 0;
    margin-top: 50px;
}

.button-vector-layer {
    background-image: url("./img/vector@1x.png");
    height: 100%;
    width: 100%;
    background-size: "cover";
}

.transparent-button {
    font-family: var(--font-family-gilroy-semibold-☞);
    color: white;
    background-image:url("./img/rectangle-3@1x.png") ;
    width: 100%;
    border: solid white 0px;
    border-radius: 15px;
    padding: 5px 0;
    margin-top: 50px;
}

.error-message {
    font-family: var(--font-family-gilroy-regular-☞);
    color: red;
    min-height: 19px;
    font-size: var(--font-size-xs);
    text-align: center;
    margin-bottom: -19px;
}

.overlap-group4 {
    height: 719px;
    margin-top: -7px;
    position: relative;
    width: 1001px;
}

.rectangle-3 {
    height: 712px;
    left: 0;
    position: absolute;
    top: 7px;
    width: 1001px;
}

.overlap-group3 {
    align-items: flex-end;
    background-color: var(--white);
    border-radius: 16.12px;
    box-shadow: 0px 3.22px 20.15px #9daaaa26;
    display: flex;
    flex-direction: column;
    left: 363px;
    min-height: 382px;
    padding: 18.5px 5.3px;
    position: absolute;
    top: 210px;
    width: 278px;
}



.group-1573 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 29px;
    min-height: 103px;
    width: 260px;
}

.username {
    align-self: center;
    letter-spacing: 0;
    margin-right: 20.51px;
    min-height: 11px;
    width: 215px;
}

.overlap-group {
    align-items: flex-start;
    border-radius: 16.12px;
    display: flex;
    height: 35px;
    margin-top: 10px;
    min-width: 254px;
    padding: 11.3px 11.3px;
}



.password {
    letter-spacing: 0;
    margin-left: 12.09px;
    margin-top: 27px;
    min-height: 18px;
}

.overlap-group1 {
    align-items: flex-start;
    border-radius: 16.12px;
    display: flex;
    height: 35px;
    margin-right: 6.0px;
    margin-top: 5px;
    min-width: 254px;
    padding: 11.3px 11.3px;
}

.enter-your-password {
    letter-spacing: 0;
    min-height: 11px;
    opacity: 0.5;
    width: 122px;
}

.forgot-you-password {
    letter-spacing: 0;
    margin-right: 8.13px;
    margin-top: 16px;
    min-height: 15px;
    min-width: 122px;
    text-align: center;
}

.overlap-group2 {
    align-items: flex-end;
    background-color: #2e54f0;
    border-radius: 16.12px;
    box-shadow: 0px 8.06px 5.64px #6efffa26;
    display: flex;
    height: 35px;
    margin-right: 6.0px;
    margin-top: 22px;
    min-width: 254px;
    padding: 10.0px 107.2px;
}

.sign-in-1 {
    color: var(--white);
    font-family: var(--font-family-gilroy-bold-☞);
    font-size: 12.1px;
    font-weight: 400;
    letter-spacing: 0;
    min-height: 15px;
    min-width: 38px;
}

.group {
    height: 63px;
    left: 0;
    position: fixed;
    top: 20%;
    width: auto;
}

.group-1 {
    height: 63px;
    left: 77px;
    position: fixed;
    top: 20%;
    width: auto;
}

.group-1578 {
    align-items: flex-start;
    display: flex;
    height: 47px;
    left: 366px;
    min-width: 271px;
    position: absolute;
    top: 98px;
}

.artboard-52x-3 {
    height: 46px;
    margin-top: -0.07px;
    object-fit: cover;
    width: 73px;
}

.vector {
    height: 712px;
    left: 3px;
    position: absolute;
    top: 0;
    width: 998px;
}
.vector-new {
    left: 0px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.group-1575 {
    align-items: flex-start;
    display: flex;
    height: 15px;
    left: 397px;
    min-width: 207px;
    position: absolute;
    top: 554px;
}

.you-dont-have-an-account {
    align-self: flex-end;
    color: var(--blue-whale-3);
    font-family: var(--font-family-gilroy-regular-☞);
    font-size: 12.1px;
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 0;
    min-height: 15px;
    min-width: 156px;
    text-align: center;
}

.sign-up {
    color: var(--blue-whale-3);
    font-family: var(--font-family-gilroy-bold-☞);
    font-size: 12.1px;
    font-weight: 400;
    letter-spacing: 0;
    margin-left: 3px;
    min-height: 15px;
    min-width: 44px;
    text-align: center;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/6293d1df1307052b936e2558.6293d1e0af1e6fe80d3b4e57.wOQeTeR.rcp.png");

@font-face {
  font-family: "Gilroy-Bold-☞";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Regular-☞";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Gilroy-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-SemiBold-☞";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Gilroy-SemiBold.ttf") format("truetype");
}
